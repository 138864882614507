@tailwind base;
@tailwind components;
@tailwind utilities;

/* * { border: 1px solid orange; } */

@layer base {
  :root {
    --foreground: 0 33 71; /* oxford blue */
    --background: 234 239 242;
    --primary: 0 33 71;
    --secondary: 113 113 122;
    --tertiary: 250 250 250;
    --clippdPink: 254 51 103;
    --border: 244 244 245;
    --nav: 0 0 102;
    --input: 82 82 91;
  }

  body {
    @apply bg-background text-gray-800 text-sm lg:text-lg;
    display: block !important;
    font-family: var(--fontNunito);
    overflow-x: hidden;
  }

  .shell-header {
    margin-right: var(--removed-body-scroll-bar-size);
  }

  .overflow-unset {
    overflow: unset;
  }

  .left-unset {
    left: unset;
  }
}

@layer utilities {
  .unset-positions {
    position: unset;
    top: unset;
    right: unset;
    bottom: unset;
    left: unset;
    transform: unset;
  }
}

@media print {
  html,
  body {
    print-color-adjust: exact;
  }
}

@import '~@spikemark/ui/styles/index.css';

[role='row'] > [data-column-id='sticky-col'],
.sticky-column {
  position: sticky !important;
  left: 0;
  z-index: 10;
  @apply border-r border-zinc-200 px-2;
}

.rdt_TableHeadRow[role='row'] > [data-column-id='sticky-col'] {
  z-index: 11;
  @apply bg-secondary border-r border-white border-opacity-20;
}

.rdt_TableHeadRow > div {
  @apply bg-secondary;
}

.legal-doc h2 {
  @apply my-1 font-bold;
}
.legal-doc h3 {
  @apply my-1 font-bold;
}
.legal-doc a {
  @apply text-clippd-pink font-normal;
}

.faq ul {
  @apply list-disc ml-4;
}

/* For when the Content Banner is disabled - we don't need to show the Display Preferences button in this case */
.termly-display-preferences--disabled {
  display: none;
}

.ad-sticky {
  @apply top-main-nav-mobile lg:top-main-nav z-40 backdrop-blur-sm bg-gradient-to-b from-white to-transparent bg-opacity-30 fixed left-0 right-0 overflow-hidden;
  animation: none;
}

.ad-sticky-hide {
  animation: ad-sticky-hide-animation 1.25s cubic-bezier(1, 0, 0.37, 1) forwards;
}

@keyframes ad-sticky-hide-animation {
  0% {
    @apply opacity-100;
  }
  100% {
    @apply shadow-none backdrop-blur-none border-transparent brightness-90 opacity-0;
    transform: translateY(-100%);
  }
}
